import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion - Condiciones generales',
  keywords: [],
  description:
    'Q°emotion es una solución de análisis semántico que transforma las reseñas de sus clientes en análisis emocionales e información. Asegúrese de leer nuestros Términos y Condiciones.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "termsConditions" }, lang: { eq: "es" } }
    ) {
      rawMarkdownBody
    }
  }
`;
